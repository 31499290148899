(function () {
    angular.module('informaApp')
        .service('DrugLevelPopupHelper', DrugLevelPopupHelper);

    function DrugLevelPopupHelper(ConstantsSvc) {
        return {
            sortDrugLevelListByPhase: (source, phaseIndex) => sortDrugLevelListByPhase(source, phaseIndex, ConstantsSvc.phasePropertyNames)
        };
    }

    //a custom sorting. Source contains array of drugGroups. Each drugGroup contains an array of drugs (1 - N), sorting goes by "success" field of passing phase
    //each array of drugs of each drugGroup should be sorted like (true, false then null)
    //each drug group should be sorted like ([true], [true+], [true+, null+], [false], [false+], [false+, null+], [(true|false|null)[2,], [null+]] (regex style)
    function sortDrugLevelListByPhase(source, currentPhaseIndex, phasePropertyNames) {
        const sortedGroups = source.map(x => {
            return _.merge({}, x, {arr: x.arr.sort((a, b) => compareBooleans(isCurrentPhaseSuccess(a, currentPhaseIndex, phasePropertyNames), isCurrentPhaseSuccess(b, currentPhaseIndex, phasePropertyNames)))});
        });

        return sortedGroups.sort((a, b) => {
            if (a.arr.every(x => isCurrentPhaseSuccess(x, currentPhaseIndex, phasePropertyNames) === null)) {
                return 1;
            }

            if (b.arr.every(x => isCurrentPhaseSuccess(x, currentPhaseIndex, phasePropertyNames) === null)) {
                return -1;
            }

            if (isDrugGroupSuccess(a, currentPhaseIndex, phasePropertyNames) && !isDrugGroupSuccess(b, currentPhaseIndex, phasePropertyNames)) {
                return -1;
            }

            if (isDrugGroupSuccess(b, currentPhaseIndex, phasePropertyNames) && !isDrugGroupSuccess(a, currentPhaseIndex, phasePropertyNames)) {
                return 1;
            }

            if (isDrugGroupSuccess(a, currentPhaseIndex, phasePropertyNames) === false && isDrugGroupSuccess(b, currentPhaseIndex, phasePropertyNames) !== false) {
                return -1;
            }

            if (isDrugGroupSuccess(b, currentPhaseIndex, phasePropertyNames) === false && isDrugGroupSuccess(a, currentPhaseIndex, phasePropertyNames) !== false) {
                return 1;
            }

            if (isDrugGroupSuccess(a, currentPhaseIndex, phasePropertyNames) === isDrugGroupSuccess(b, currentPhaseIndex, phasePropertyNames)) {
                if (a.arr.some(x => isCurrentPhaseSuccess(x, currentPhaseIndex, phasePropertyNames) === null)) {
                    return 1;
                }

                if (b.arr.some(x => isCurrentPhaseSuccess(x, currentPhaseIndex, phasePropertyNames) === null)) {
                    return -1;
                }

                return a.arr.length - b.arr.length;
            }

            return 0;
        });
    }

    function isDrugGroupSuccess(drugGroup, currentPhaseIndex, phases) {
        const notNull = (x) => isCurrentPhaseSuccess(x, currentPhaseIndex, phases) !== null;

        const items = drugGroup.arr.filter(notNull);

        return items.every(x => isCurrentPhaseSuccess(x, currentPhaseIndex, phases) === true)
            ? true
            : (items.every(x => isCurrentPhaseSuccess(x, currentPhaseIndex, phases) === false) ? false : null);
    }

    function isCurrentPhaseSuccess(drugGroupItem, currentPhaseIndex, phases) {
        return drugGroupItem[phases[currentPhaseIndex]].success;
    }

    function compareBooleans(a, b) {
        if (a === null) {
            return 1;
        }

        if (b === null) {
            return -1;
        }

        if (a === b) {
            return 0;
        }

        return a ? -1 : 1;
    }
})();
